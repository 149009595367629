import * as React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../components/layout/layout"
import Back from "../components/partials/svg/back"
import AvatarErrorSVG from "../components/partials/svg/avatarError"

// Markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div id="not-foud">
        <Back url="/" styleClass="back-link--black" text="accueil" />
        <div className="main-page-content container">
          <div className="not-found-content">
            <section className="not-found-content__title">
              <h1>404: Not Found</h1>
            </section>
            <section className="not-found-content__description">
              <p>Vous venez de prendre une route qui n'existe pas ... la tristesse.</p>
              <p>Clickez <Link to="/" style={{fontWeight: "bold"}}>ici</Link> pour revenir sur le droit chemin.</p>
            </section>
            <AvatarErrorSVG styleClass="svg-avatar"/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
