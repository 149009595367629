import React from "react"

// Markup
const Back = ({ url, styleClass, text }) => {
  return (
    <div className={styleClass}>
       <a href={url}>
        <svg width="119" height="69" viewBox="0 0 119 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M70.0584 41.1679C74.1754 47.0519 80.7334 59.6139 70.2374 63.0179C63.5584 65.1829 53.9044 62.5699 50.3024 56.2029C45.7884 48.2229 53.5524 41.4609 60.1674 38.4099C62.0714 37.5309 64.0554 36.8049 66.0874 36.1889C67.5034 37.7719 68.8394 39.4259 70.0584 41.1679ZM117.991 33.0819C102.417 28.5349 84.5164 27.2659 68.4074 31.1409C65.7744 28.4889 63.0794 26.1759 60.7024 24.3519C46.8924 13.7569 27.9294 6.53595 10.4884 5.73395C8.58243 5.64695 7.75943 7.80495 8.43943 9.21795C7.97043 8.47795 7.51043 7.73195 7.05943 6.97995C9.06743 5.88295 11.1834 5.13595 13.5234 4.71295C14.9534 4.45495 16.5444 4.59195 17.9294 4.22895C18.7544 4.01295 18.7904 3.40595 19.7214 3.29295C20.0184 3.25695 20.2014 2.85495 19.8874 2.68195C19.0274 2.20895 18.8094 1.40895 17.9294 0.971946C16.5434 0.282946 13.8514 0.388946 12.3814 0.490946C8.72043 0.744946 5.25443 1.98095 2.09343 3.80195C0.773428 4.56295 0.519428 6.15295 1.13843 7.44595C2.65543 10.6159 4.62243 13.5709 6.75243 16.3589C8.59843 18.7739 10.5394 21.6369 13.2734 23.0639C15.4164 24.1839 17.6624 21.9389 16.5434 19.7929C15.1784 17.1739 12.6984 15.1699 10.9074 12.8139C10.1394 11.8039 9.43643 10.7599 8.75443 9.70295C9.02343 10.0019 9.38943 10.2309 9.86543 10.3379C25.2444 13.7939 39.9534 16.6649 53.4304 25.4359C56.7414 27.5889 59.8814 30.0359 62.7554 32.7589C62.4114 32.8719 62.0594 32.9709 61.7154 33.0889C50.4154 36.9929 37.6344 49.0839 47.8294 61.1059C55.0644 69.6399 75.7424 73.2619 80.1854 59.9049C83.0244 51.3709 78.1244 42.1789 71.7534 34.7629C76.7714 33.7319 81.9324 33.1989 86.8624 32.7449C97.1394 31.7969 107.603 32.3189 117.816 33.7149C118.221 33.7699 118.41 33.2039 117.991 33.0819Z"/>
        </svg>
        {text}
       </a>
    </div>
  )
}
export default Back
